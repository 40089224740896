import { DescriptionPlanComponent } from './../modals/description-plan/description-plan.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {SocialIconsComponent} from './Social/SocialIcons/SocialIcons.component';
import {TestimonialComponent} from './Slider/Testimonial/Testimonial.component';
import {LetsTalkComponent} from './LetsTalk/LetsTalk.component';
import {ServiceGridComponent} from './ServiceGrid/ServiceGrid.component';
import {TemplateModule} from '../Template/Template.module';
import {WidgetHeadingComponent} from './WidgetHeading/WidgetHeading.component';
import {RecentWorkComponent} from './RecentWork/RecentWork.component';
import {ExploreStdioComponent} from './ExploreStudio/ExploreStudio.component';
import {ClientSaysComponent} from './ClientSays/ClientSays.component';
import {ContactComponent} from './Contact/Contact.component';
import {ContactFormComponent} from './Form/ContactForm/ContactForm.component';
import {ContactContentComponent} from './ContactContent/ContactContentV1/ContactContentV1.component';
import {SubscribeComponent} from './Subscribe/Subscribe/Subscribe.component';
import {ThemeDarkLogoComponent} from './Logo/ThemeDarkLogo/ThemeDarkLogo.component';
import {ThemeLightLogoComponent} from './Logo/ThemeLightLogo/ThemeLightLogo.component';
import {WorkingHoursComponent} from './WorkingHours/WorkingHours.component';
import {SearchComponent} from './Search/SearchV1/SearchV1.component';
import {PostListComponent} from './List/PostList/PostList.component';
import {CategoriesComponent} from './List/Categories/Categories.component';
import {TagComponent} from './Tag/Tag.component';
import {RecentCommentsComponent} from './List/RecentComments/RecentComments.component';
import {BannerComponent} from './Banner/Banner/Banner.component';
import {LeavecommentComponent} from './Form/LeaveComment/LeaveComment.component';
import {AboutAuthorComponent} from './AboutAuthor/AboutAuthor.component';
import {PaginationComponent} from './Pagination/Pagination.component';
import {TeamFounderComponent} from './TeamFounder/TeamFounder.component';
import {TeamMemberComponent} from './Slider/TeamMember/TeamMember.component';
import {ClientSliderComponent} from './Slider/ClientSlider/ClientSlider.component';
import {ServiceItemComponent} from './Slider/ServiceItemSlider/ServiceItemSlider.component';
import {BannerSliderComponent} from './Slider/BannerSlider/BannerSlider.component';
import {HomeBannerComponent} from './Banner/HomeBanner/HomeBanner.component';
import {ContactFormV2Component} from './Form/ContactFormV2/ContactFormV2.component';
import {ContactContentV2Component} from './ContactContent/ContactContentV2/ContactContentV2.component';
import {MapComponent} from './Map/Map.component';
import {SearchV2Component} from './Search/SearchV2/SearchV2.component';
import {CounterComponent} from './Counter/Counter.component';
import {BannerV2Component} from './Banner/BannerV2/BannerV2.component';
import {PricingBoxesComponent} from './pricing-boxes/pricing-boxes.component';
import {HeroAboutComponent} from './hero-about/hero-about.component';
import {MethodologyComponent} from './methodology/methodology.component';
import {CoursesListComponent} from './courses-list/courses-list.component';
import {PricingListComponent} from './pricing-list/pricing-list.component';
import {FaqsComponent} from './faqs/faqs.component';
import {FaqItemComponent} from './faq-item/faq-item.component';
import {LessonBoxComponent} from './lesson-box/lesson-box.component';
import {TranslateModule} from '@ngx-translate/core';
import {ChatBoxComponent} from './Chat/box/box.component';
import {ChatItemComponent} from './Chat/item/item.component';
import {ThanksBuyComponent} from './Buy/ThanksBuy.component';
import {BlockCourseListComponent} from './blocks-course-list/block-course-list.component';
import {ProductBoxComponent} from './Product/box/box.component';
import {ProductItemComponent} from './Product/item/item.component';
import {CircularProgressBarComponent} from './progress-bar/circular-progress-bar/circular-progress-bar.component';
import {ProgressBarComponent} from './progress-bar/progress-bar/progress-bar.component';
import {ReviewFormComponent} from './review-form/review-form.component';
import {InvoiceModalComponent} from './modal/invoice-modal.component';
import {GetMpEmailModalComponent} from './get-mp-email/getmpemail-modal.component';
import {SignUpWidgetFormComponent} from './SignUpForm/SignUpForm.component';
import {ImageComponent} from './Image/image.component';
import {ContestParticipantComponent} from './contest-participant/contest-participant.component';
import {PipesModule} from '../Pipes/Pipes.module';
import {SidebarPostComponent} from './Blogs/Sidebar/sidebar-post/sidebar-post.component';
import {WidgetsCardComponent} from './Blogs/widgets-card/widgets-card.component';
import {LoginComponent} from './login/login.component';
import {RegisterComponent} from './register/register.component';
import {BlogCommentComponent} from './BlogComment/BlogComment.component';
import {ForumWidgetComponent} from './Forum/forum.component';
import {BlockComponent} from './block/block.component';
import {BlockProgramsComponent} from './block-programs/block-programs.component';
import {BlockSlidersComponent} from './block-sliders/block-sliders.component';
import {BlockTextComponent} from './block-text/block-text.component';
import {BlockPlansComponent} from './block-plans/block-plans.component';
import {BlockProductsComponent} from './block-products/block-products.component';
import {BlockEventsComponent} from './block-events/block-events.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { BannerPlan } from './banner-plan/BannerPlan.component';
import { ModalsModule } from '../modals/modals.module';

@NgModule({
  declarations: [
    SignUpWidgetFormComponent,
    SocialIconsComponent,
    TestimonialComponent,
    LetsTalkComponent,
    ServiceGridComponent,
    WidgetHeadingComponent,
    RecentWorkComponent,
    ExploreStdioComponent,
    ClientSaysComponent,
    ContactComponent,
    ContactFormComponent,
    ContactContentComponent,
    SubscribeComponent,
    ThemeDarkLogoComponent,
    ThemeLightLogoComponent,
    WorkingHoursComponent,
    SearchComponent,
    BannerComponent,
    PostListComponent,
    CategoriesComponent,
    TagComponent,
    RecentCommentsComponent,
    BannerPlan,
    LeavecommentComponent,
    AboutAuthorComponent,
    PaginationComponent,
    TeamFounderComponent,
    TeamMemberComponent,
    ClientSliderComponent,
    ServiceItemComponent,
    BannerSliderComponent,
    HomeBannerComponent,
    ContactFormV2Component,
    ContactContentV2Component,
    MapComponent,
    SearchV2Component,
    CounterComponent,
    BannerV2Component,
    PricingBoxesComponent,
    HeroAboutComponent,
    MethodologyComponent,
    CoursesListComponent,
    PricingListComponent,
    FaqsComponent,
    FaqItemComponent,
    LessonBoxComponent,
    ChatBoxComponent,
    ChatItemComponent,
    ThanksBuyComponent,
    ProductBoxComponent,
    ProductItemComponent,
    CircularProgressBarComponent,
    ProgressBarComponent,
    ReviewFormComponent,
    InvoiceModalComponent,
    GetMpEmailModalComponent,
    ImageComponent,
    ContestParticipantComponent,
    SidebarPostComponent,
    WidgetsCardComponent,
    LoginComponent,
    RegisterComponent,
    BlogCommentComponent,
    ForumWidgetComponent,
    BlockCourseListComponent,
    BlockComponent,
    BlockProgramsComponent,
    BlockSlidersComponent,
    BlockTextComponent,
    BlockPlansComponent,
    BlockProductsComponent,
    BlockEventsComponent,
    ProductCardComponent,
    DescriptionPlanComponent,
  ],
  imports: [
    
    CommonModule,
    TemplateModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    PipesModule,
    InfiniteScrollModule,
  ],
  exports: [
    DescriptionPlanComponent,
    BlockCourseListComponent,
    SocialIconsComponent,
    TestimonialComponent,
    LetsTalkComponent,
    ServiceGridComponent,
    WidgetHeadingComponent,
    RecentWorkComponent,
    ExploreStdioComponent,
    ClientSaysComponent,
    ContactComponent,
    ContactFormComponent,
    ContactContentComponent,
    SubscribeComponent,
    ThemeDarkLogoComponent,
    ThemeLightLogoComponent,
    WorkingHoursComponent,
    CategoriesComponent,
    PostListComponent,
    SearchComponent,
    TagComponent,
    RecentCommentsComponent,
    BannerComponent,
    LeavecommentComponent,
    AboutAuthorComponent,
    PaginationComponent,
    TeamFounderComponent,
    TeamMemberComponent,
    ClientSliderComponent,
    ServiceItemComponent,
    BannerSliderComponent,
    HomeBannerComponent,
    ContactFormV2Component,
    ContactContentV2Component,
    MapComponent,
    SearchV2Component,
    BannerV2Component,
    BannerPlan,
    CounterComponent,
    PricingBoxesComponent,
    HeroAboutComponent,
    MethodologyComponent,
    CoursesListComponent,
    PricingListComponent,
    FaqsComponent,
    LessonBoxComponent,
    ChatBoxComponent,
    ChatItemComponent,
    ThanksBuyComponent,
    SignUpWidgetFormComponent,
    ProductBoxComponent,
    ProductItemComponent,
    CircularProgressBarComponent,
    ProgressBarComponent,
    ReviewFormComponent,
    InvoiceModalComponent,
    GetMpEmailModalComponent,
    ImageComponent,
    ContestParticipantComponent,
    WidgetsCardComponent,
    SidebarPostComponent,
    LoginComponent,
    RegisterComponent,
    BlogCommentComponent,
    ForumWidgetComponent,
    BlockComponent,
    ProductCardComponent
  ],
})
export class WidgetsModule {
}
