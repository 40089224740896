import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {InitProvider} from './init-provider';
import {AuthService} from './Services/Auth/auth.service';
import {FaviconService} from './Services/favicon.service';
import {NgcCookieConsentService} from 'ngx-cookieconsent';
import {DomSanitizer} from '@angular/platform-browser';
import {CookieService} from './Services/cookie.service';
import {TRACKING_CHECKOUT_VISIT_COOKIE, TRACKING_WEB_VISIT_COOKIE, TRACKING_WEB_VISIT_EVENT, TrackingService} from './Services/tracking.service';
import { DOCUMENT } from '@angular/common';
declare let fbq: Function;
import { Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit , AfterViewInit{

  customCSS: string;
  company: any;

  constructor(
    public sanitizer: DomSanitizer,
    public router: Router,
    public translateProvider: TranslateService,
    public initProvider: InitProvider,
    public authService: AuthService,
    public favicon: FaviconService,
    private titleService: Title,
    private ccService: NgcCookieConsentService,
    private cookieService: CookieService,
    private trackingService: TrackingService,
    private renderer: Renderer2,
     @Inject(DOCUMENT) private document: Document,
     private meta: Meta
  ) {
    router.events.subscribe((y: NavigationEnd) => {
      if (y instanceof NavigationEnd) {
        console.log('Facebook pixel');
        if (typeof fbq !== 'undefined') {
          fbq('track', 'PageView');
        }
      }
    });

    const company = initProvider.getSettings();
    this.company = company;
    
    if (company.favicon) {
      favicon.set(company.favicon);
    }

    const text_font = company.font_text;
    const font_title = company.font_title;
    this.customCSS = company.custom_css ? company.custom_css : '';
    if (text_font) {
      document.documentElement.style.setProperty('--text-font', text_font);
    }

    if (font_title) {
      document.documentElement.style.setProperty('--title-font', font_title);
    }

    document.documentElement.style.setProperty('--primary-color', company.color_1);
    document.documentElement.style.setProperty('--secondary-color', company.color_2);
    document.documentElement.style.setProperty('--info-color', company.color_3);
    document.documentElement.style.setProperty('--extra-color', company.color_4);
    document.documentElement.style.setProperty('--info-font-color', company.color_5);
    document.documentElement.style.setProperty('--primary-color-darken', this.lightenDarkenColor(company.color_1, -20));
    document.documentElement.style.setProperty('--secondary-color-darken', this.lightenDarkenColor(company.color_2, -20));
    document.documentElement.style.setProperty('--info-color-darken', this.lightenDarkenColor(company.color_3, -20));


    this.translateProvider.setDefaultLang('en');
    if (authService.user) {
      this.translateProvider.use(authService.user.language);
    } else {
      this.translateProvider.use(company.language);
    }
    this.titleService.setTitle(company.name);
    //this.titleService.setTitle(company.name + ' - Mootiva');

    // for cookies
    if (company.cookies_warning) {
      // set domian in cookie plugin
      // let dom='.mootiva.me';
      // let dom=window.location.href.split('/')[2];
      const dom = window.location.hostname;
      console.log('Dominio: ' + dom);
      this.ccService.getConfig().cookie.domain = dom;
      // color and styles
      this.ccService.getConfig().palette.popup.background = '#dee0e1';
      this.ccService.getConfig().palette.button.background = company.color_3;
      this.ccService.getConfig().palette.button.text = company.color_5;
      this.ccService.getConfig().palette.button.link = company.color_1;
      this.ccService.getConfig().palette.popup.link = '#000';
      this.ccService.getConfig().palette.popup.border = '#000';
      this.ccService.getConfig().palette.popup.text = company.color_1;

      // traduction for cookies
      this.translateProvider//
        .get(['cookie.header', 'cookie.message', 'cookie.dismiss', 'cookie.allow', 'cookie.deny', 'cookie.link', 'cookie.policy'])
        .subscribe(data => {
          this.ccService.getConfig().content = this.ccService.getConfig().content || {};
          // Override default messages with the translated ones
          this.ccService.getConfig().content.header = data['cookie.header'];
          this.ccService.getConfig().content.message = data['cookie.message'];
          this.ccService.getConfig().content.dismiss = data['cookie.dismiss'];
          this.ccService.getConfig().content.allow = data['cookie.allow'];
          this.ccService.getConfig().content.deny = data['cookie.deny'];
          this.ccService.getConfig().content.link = ' <a href="#" class="text-info" data-toggle="modal" data-target="#modal-cookie">' + data['cookie.link'] + '</a>',
            this.ccService.getConfig().content.policy = data['cookie.policy'];
          this.ccService.destroy(); // remove previous cookie bar (with default messages)
          this.ccService.init(this.ccService.getConfig()); // update config with translated messages
        });
    } else { // if no data in cookie. Plug in deactivated
      this.ccService.destroy(); // remove previous cookie bar (with default message
    }


    const gaTrackingId = company.google_analytics_id;
    if (gaTrackingId) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackingId;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${gaTrackingId}');
      `;
      document.head.appendChild(gaScript);
    }

    const google_ads_id = company.google_ads_id;
    if (google_ads_id) {
      // register google tag manager
      const gTagManagerScript = document.createElement('script');
      gTagManagerScript.async = true;
      gTagManagerScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + google_ads_id;
      document.head.appendChild(gTagManagerScript);

      // register google analytics
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${google_ads_id}');
      `;
      document.head.appendChild(gaScript);
    }


    const fecebook_pixel_id = company.facebook_pixel_id;
    if (fecebook_pixel_id) {
      // register facebook pixel
      const gaScript = document.createElement('script');
      gaScript.innerHTML = `
       !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${fecebook_pixel_id}');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(gaScript);


      const gaScript2 = document.createElement('noscript');
      gaScript2.innerHTML = `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=${fecebook_pixel_id}&ev=PageView&noscript=1"
      />
      `;
      document.head.appendChild(gaScript2);
    }


    const hotjar_id = company.hotjar_id;
    console.log('hotjar', hotjar_id);
    if (hotjar_id) {
      console.log('hay hotjar');
      // register facebook pixel
      const hJScript = document.createElement('script');
      hJScript.innerHTML = `
      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${hotjar_id},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;

      document.head.appendChild(hJScript);
    }

  }

  ngOnInit() {
    const style = document.createElement('style');
    style.innerHTML = this.customCSS;
    document.head.appendChild(style);
    this.handleVisit();
  }

  ngAfterViewInit() {
    if (this.company) {
      this.addOpenGraphMetaTags();
    }
  }
  public addOpenGraphMetaTags() {
 
    // Add new OG meta tags
    if (this.company?.marketing_title) {
      this.meta.updateTag({ property: 'og:title', content: this.company?.marketing_title });
    }else{
      this.meta.updateTag({ property: 'og:title', content: this.company?.name + ' es una academia educativa' });
    }
    if (this.company?.marketing_description) { 
      this.meta.updateTag({ property: 'og:description', content: this.company?.marketing_description });
    }else{
      this.meta.updateTag({ property: 'og:description', content: this.company?.name + ' es una academia educativa que contiene Cursos, Eventos, Productos Digitales e Información Educativa.' });
      
    }
    this.meta.updateTag({ property: 'og:url', content: this.company?.base_url });
    this.meta.updateTag({ property: 'og:image', content: this.company?.logo?.original });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
  }

  handleVisit(): void {
    this.trackingService.handleTracking(TRACKING_WEB_VISIT_COOKIE, TRACKING_WEB_VISIT_EVENT, 7);
  }

  lightenDarkenColor(col, amt) {
    let usePound = false;

    if (col[0] === '#') {
      col = col.slice(1);
      usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00FF) + amt;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000FF) + amt;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }
}
