
<div *ngIf="authService.user" class="modal fade " id="modal-timezone" tabindex="-1" role="dialog" aria-labelledby="Aviso Legal">
    <div class="modal-dialog-width modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal-timezone" style="border-radius: 20px;">
           
            <div class="modal-body">
                        <div class="row justify-content-end mr-1">
                            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="container">
                            <div class="row">
                                <div class="col-12 mt-1 text-center onResponsiveTop mb-5">
                                    <h2 class="text-info text-info-timezone">{{ "Hello" | translate }} <span class="text-info text-info-timezone">{{ authService.user.first_name }}!</span></h2>
                                           
                                        <div *ngIf="currentUser?.company?.request_billing_info">

                                            <div class="row mt-2 no-padding">
                                                <div class="col-12 center-title" style="padding-top: 10px;"> {{ 'Please complete the billing information' | translate}} </div>
                                                
                                            </div>
                            
                                            <div class="row mt-2 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Fullname' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_name" class="lumi-input-text"
                                                          
                                                        />
                                                    <span class="help is-danger" *ngIf="errName">{{'Please fill in the name' | translate}} </span>
                                                    <span class="help is-danger" *ngIf="errNameSplit">{{'Please enter a valid full name' | translate}} </span>
                                                    
                                                </div>
                                            </div>
                                            <div class="row mt-2 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'DNI/RUT' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_dni" class="lumi-input-text"
                                                        
                                                        />
                                                        <span class="help is-danger" *ngIf="errDni">{{'Please complete the DNI / RUT' | translate}} </span>
                                                    
                                                </div>
                                            </div>
                                    
                                              
                                            <div class="row mt-2 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Invoice Country' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <select style="width: 100%;" class="lumi-input-text"
                                                        [(ngModel)]="currentUser.invoice_country" >
                                                        <option value="">{{'Select an option'|translate}}</option>
                                                        <option *ngFor="let country of countries" [value]="country.name">{{ country.name }}</option>
                                                    </select>
                                                    <span class="help is-danger" *ngIf="errCountry">{{'Please complete the Country' | translate}}</span>                                                    
                                                </div>
                                            </div>
                                            <div class="row mt-2 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Invoice Email' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_email" class="lumi-input-text"
                                                           
                                                        />
                                                        <span class="help is-danger" *ngIf="errEmail">{{'Please complete the Email' | translate}}</span>
                                                        <span class="help is-danger" *ngIf="errEmailInvalid">{{'Please enter a valid email' | translate}}</span>
                                                    
                                                </div>
                                            </div>

                                           


                                            <div class="row mt-2 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Phone number' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.phone_number_invoice" class="lumi-input-text"
                                                           
                                                        />
                                                        <span class="help is-danger" *ngIf="errPhone">{{'Please complete the Phone number' | translate}}</span>
                                                        <span class="help is-danger" *ngIf="errPhoneInvalid">{{'Please enter a valid phone number' | translate}}</span>
                                                    
                                                </div>
                                            </div>

                            
                                            <div class="row mt-2 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Business name (only for companies)' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_razon_social" class="lumi-input-text"
                                                          
                                                        />
                                                </div>
                                            </div>
                                            <div class="row mt-2 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Giro (only for companies)' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_giro" class="lumi-input-text"
                                                           
                                                        />
                                                </div>
                                            </div>
                                            <div class="row mt-2 no-padding">
                                                <div class="col-4 leftInfoTitle" style="padding-top: 10px;"> {{ 'Billing Address (only for companies)' | translate}} </div>
                                                <div class="col-8 rightInfoText no-padding">
                                                    <input type="text" style="width: 100%;" [(ngModel)]="currentUser.invoice_address" class="lumi-input-text"
                                                            
                                                        />
                                                </div>
                                            </div>
            
                                            <div class="col-md-12 row mt-5 no-padding" style="display: inline-block;  background: transparent; ">
                                                <button class="btn btn-info" (click)="onSaveProfile()">
                                                        {{"Save"|translate}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            </div>  
                        </div>
            </div>
        </div>
    </div>
  </div>

