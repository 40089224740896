import { Injectable } from "@angular/core";
import { ApiService } from "./Api.service";

@Injectable({
  providedIn: "root",
})
export class LandingService {
  public currenLanding: any = {};
  constructor(private apiService: ApiService) {}

  async getLanding(landingId: string) {
    try {
      const landing = await this.apiService.get(`public/landing/${landingId}`);
      return landing;
    } catch (error) {
      return error;
    }
  }
  async sendEmail(landingId: string, email: string) {
    try {
      const LandingEmail = await this.apiService.post(
        `public/landing/${landingId}`,
        { email }
      );
      return LandingEmail;
    } catch (error) {
      return error;
    }
  }
}
