<div class="block"
     [id]="block.show_in_menu ? cleanString(block?.show_in_menu_title)?.toLowerCase() : null"
     [ngStyle]="getBlockStyles()">
  <app-block-course-list
    *ngIf="block.type == 'course'"
    [block]="block"
    [slice]="block.max_item"
    [company]="company"
    [fromHome]="fromHome"
   
    >

  </app-block-course-list>
  <app-block-programs
    *ngIf="block.type === 'program'"
    [block]="block"
    [company]="company"
    [logged]="logged"
    [slice]="block.max_item">
  </app-block-programs>


  <app-block-events
  *ngIf="block.type === 'event'"
  [block]="block"
  [company]="company"
  [logged]="logged"
  [slice]="block.max_item">
</app-block-events>



  <app-block-sliders
    *ngIf="block.type === 'slider'"
    [block]="block"
  >
  </app-block-sliders>
  <app-block-text
    *ngIf="block.type === 'text'"
    [block]="block"
    [company]="company"
  >
  </app-block-text>
  <app-block-plans
    *ngIf="block.type === 'plan'"
    [logged]="logged"
    [block]="block">
  </app-block-plans>
  
    <app-block-products
    *ngIf="block.type === 'product'"
    [block]="block"
    [company]="company"
    [logged]="logged"
    [slice]="block.max_item">
  >
  </app-block-products>

</div>
