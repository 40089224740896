import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {MainComponent} from './Main/Main.component';
import {HomeComponent} from './Pages/Home/Home.component';
import {BlockComponent} from './Pages/Block/Block.component';
import {MaintenanceComponent} from './Pages/Session/Maintenance/Maintenance.component';
import {LoginComponent} from './Pages/Session/LogIn/LogIn.component';
import {ForgotPasswordComponent} from './Pages/Session/ForgotPassword/ForgotPassword.component';

import {Signup2Component} from './Pages/Session/SignUp2/SignUp2.component';

import {GuardService as AuthGuard} from './Services/Auth/guard.service';
import {PublicGuardService as PublicGuard} from './Services/Auth/publicGuard.service';
import {MeetComponent} from './meet/meet.component';
import {RegistrationAllowedService as RegistrationAllowedGuard} from './Services/Auth/registrationAlowed.service';
import { MainLandingComponent } from './Pages/landing/main-landing/main-landing.component';
import { ThankYouLandingComponent } from './Pages/landing/thank-you-landing/thank-you-landing.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'meet',
    component: MeetComponent,
  },
  {
    path: 'landing/thank-you',
    component: ThankYouLandingComponent,
  },
  {
    path: 'landing/:id',
    component: MainLandingComponent,
    // children: [
    //   {
    //     path: "thank-you",
    //     component: ThankYouLandingComponent,
    //   },
    //   {
    //     path: ":id",
    //     component: MainLandingComponent,
    //   },
    // ],
  },

  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [PublicGuard],
      },
      {
        path: 'block/:id',
        component: BlockComponent,

      },
      {
        path: 'session/maintenance',
        component: MaintenanceComponent,
        canActivate: [PublicGuard],
      },
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [PublicGuard],
      },
      {
        path: 'forgot',
        component: ForgotPasswordComponent,
        canActivate: [PublicGuard],
      },
      {
        path: 'register',
        component: Signup2Component,
        canActivate: [RegistrationAllowedGuard],
      },
      {
        path: 'logged',
        loadChildren: () =>
          import('./Pages/HomeLogged/home-logged.module').then(
            (m) => m.HomeLoggedModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'courses',
        loadChildren: () =>
          import('./Pages/Courses/Courses.module').then((m) => m.CoursesModule),
      },
      {
        path: 'programs',
        loadChildren: () =>
          import('./Pages/programs/programs.module').then(
            (m) => m.ProgramsModule
          ),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('./Pages/Events/events.module').then((m) => m.EventsModule),
      },
      {
        path: 'teachers',
        loadChildren: () =>
          import('./Pages/Teachers/teachers.module').then(
            (m) => m.TeachersModule
          ),
      },
      {
        path: 'certificates',
        loadChildren: () =>
          import('./Pages/Certificates/certificates.module').then(
            (m) => m.CertificatesModule
          ),
      },
      {
        path: 'contests',
        loadChildren: () =>
          import('./Pages/Contests/contests.module').then(
            (m) => m.ContestsModule
          ),
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./Pages/products-page/products-page.module').then(
            (m) => m.ProductsPageModule
          ),
        // canActivate: [AuthGuard],
      },
      {
        path: 'purchases',
        loadChildren: () =>
          import('./Pages/Purchases/Purchases.module').then(
            (m) => m.PurchasesModule
          ),
      },
      {
        path: 'blog',
        loadChildren: () =>
          import('./Pages/blogs/blogs.module').then((m) => m.BlogsModule),
      },
      {
        path: '**',
        redirectTo: 'home',
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes, {
      onSameUrlNavigation: 'ignore',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
}
