<div *ngIf="loading" class="row justify-content-center">
  <div class="col-auto">
    <div class="loader-wrapper text-center m-5">
      <div><i class="fas fa-circle-notch fa-spin fa-2x"></i></div>
    </div>
  </div>
</div>
<ng-container *ngIf="!loading">
  <ng-container *ngIf="type == 'Appointment Setter'">
    <app-appointment-setter
      [infoLanding]="infoLanding"
    ></app-appointment-setter>
  </ng-container>
  <ng-container *ngIf="type == 'Lead Magnet'">
    <app-lead-magnet [infoLanding]="infoLanding"></app-lead-magnet>
  </ng-container>
</ng-container>
