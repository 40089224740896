import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LandingService } from "src/app/Services/landing.service";

@Component({
  selector: "app-main-landing",
  templateUrl: "./main-landing.component.html",
  styleUrls: ["./main-landing.component.scss"],
})
export class MainLandingComponent implements OnInit {
  public type: string = "";
  public landingId: string = "13";
  public loading: boolean = false;
  public infoLanding: any = {};
  constructor(
    private ladingService: LandingService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.landingId = params.get("id");
      this.getInfoLAnding();
    });
  }

  public getInfoLAnding() {
    this.loading = true;
    this.ladingService
      .getLanding(this.landingId)
      .then((infoLanding) => {
        this.infoLanding = infoLanding?.data;
        this.ladingService.currenLanding = this.infoLanding;
        this.type = this.infoLanding?.type;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        this.loading = false;
      });
  }
}
