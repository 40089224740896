import {Injectable} from '@angular/core';
import {ApiService} from './Api.service';
import {AuthService} from 'src/app/Services/Auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BlocksService {


  publicUrl = 'public/blocks';
  privateUrl = 'login/blocks';
  useURL = '';
  baseDataUrl = 'data';

  currentCourse: any;
  courses: any;

  constructor(private apiService: ApiService,
              public authService: AuthService) {

    let currentUser = this.authService.user;
    if (currentUser) {
      this.useURL = this.privateUrl;
    } else {
      this.useURL = this.publicUrl;

    }
  }

  async getBlocks() {
    try {
      const courses = await this.apiService.get(this.useURL);
      this.courses = courses;
      return courses;
    } catch (error) {
      return error;
    }
  }

  async getBlocksById(id) {
    try {
      const courses = await this.apiService.get(this.useURL + '/' + id);
      this.courses = courses;
      return courses;
    } catch (error) {
      return error;
    }
  }


}
